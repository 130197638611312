export type Path = string & { __brand: 'Path' };

export class PathUtils {
	static isPath(path: string): path is Path {
		// TODO: Make validation
		return true;
	}

	static split(path: Path): string[] {
		return path.split('/');
	}

	static fullPath(parentPath: Path, fileName: string): Path {
		return `${parentPath}/${fileName}` as Path;
	}

	static parsePath(fullPath: string): [Path, string] {
		const constructedPath = fullPath.split('/');
		const parentPath = constructedPath.length > 1 ? constructedPath.slice(0, -1).join('/') : '/';
		const fileName = constructedPath.length > 1 ? constructedPath.slice(-1)[0] : fullPath;
		return [parentPath as Path, fileName];
	}
}
