import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Chip } from '@warehouse/shared/legacy-ui';
import { InventoryItem, InventoryItemType } from '@warehouse/inventory/core';

export function StorageTierCell({ row }: GridRenderCellParams<InventoryItem, string>) {
	if (row.itemType !== InventoryItemType.File) return null;
	const statusLabel = row.online ? row.storageTier.label : 'Offline';
	return <Chip label={statusLabel} />;
}
