import React from 'react';
import { PageHeader } from '@warehouse/inventory/feature-page-header';
import { ListInventory } from '@warehouse/inventory/feature-list-inventory';
import { InventoryStoreProvider } from '@warehouse/inventory/domain';
import { Card } from '@mui/material';
import { CardWrapper, Wrapper } from './styles';

export function Inventory() {
	return (
		<InventoryStoreProvider>
			<Wrapper>
				<CardWrapper>
					{false && (
						<Card sx={{ minWidth: 275, overflow: 'visible' }}>
							<PageHeader />
						</Card>
					)}
					<Card style={{ flex: 1, borderRadius: 8 }}>
						<ListInventory />
					</Card>
				</CardWrapper>
			</Wrapper>
		</InventoryStoreProvider>
	);
}
