import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { InventoryItem } from '@warehouse/inventory/core';
import { inventoryStoreSelector, useInventoryStore } from '@warehouse/inventory/domain';
import { columns } from '../columns';
import { CustomColumnMenu } from './CustomColumnMenu';
import { InventoryPaginationNavigator } from './InventoryPaginationNavigator';
import { gridFilterModelToMuixFilterModel } from '../../../shared/ui/table/muix-filter';

export function Table() {
	const filter = useInventoryStore(inventoryStoreSelector.filter);
	const sort = useInventoryStore(inventoryStoreSelector.sort);
	const visibility = useInventoryStore(inventoryStoreSelector.visibility);
	const pinned = useInventoryStore(inventoryStoreSelector.pinned);
	const order = useInventoryStore(inventoryStoreSelector.order);
	const items = useInventoryStore(inventoryStoreSelector.items);
	const isPending = useInventoryStore(inventoryStoreSelector.isPending);

	const {
		onSortModelChange,
		onColumnVisibilityModelChange,
		onPinnedColumnsChange,
		onColumnOrderChange,
		onFilterModelChange,
	} = useInventoryStore(inventoryStoreSelector.actions);
	const pagination = useCallback(() => <InventoryPaginationNavigator centered />, []);

	return (
		<StyledDataGridPro<FC<DataGridProProps<InventoryItem>>>
			style={{ borderRadius: 8 }}
			columns={columns}
			rows={items ?? []}
			initialState={{
				pinnedColumns: {
					left: Array.from(new Set(['itemType', ...pinned.left])),
				},
				sorting: {
					sortModel: sort,
				},
				columns: {
					columnVisibilityModel: visibility,
					orderedFields: order,
				},
				filter: {
					filterModel: gridFilterModelToMuixFilterModel(filter),
				},
			}}
			getRowId={(row) => row.id}
			loading={isPending}
			rowSelection={false}
			sortingMode="server"
			onSortModelChange={onSortModelChange}
			filterMode="server"
			onFilterModelChange={onFilterModelChange}
			onColumnVisibilityModelChange={onColumnVisibilityModelChange}
			onPinnedColumnsChange={onPinnedColumnsChange}
			onColumnOrderChange={onColumnOrderChange}
			getRowClassName={({ indexRelativeToCurrentPage, row }) =>
				indexRelativeToCurrentPage % 2 === 0 ? `even ${row.itemType}` : `odd ${row.itemType}`
			}
			slots={{
				pagination,
				columnMenu: CustomColumnMenu,
			}}
			pagination
		/>
	);
}

const StyledDataGridPro = styled(DataGridPro)(
	({ theme }) => css`
		// Display headers and odd rows gray
		.MuiDataGrid-columnHeaders,
		.odd {
			background-color: ${theme.palette.light.background};
		}

		// Set hovering cell styling
		.MuiDataGrid-cell[data-field='name'] {
			border: 1px solid transparent;
		}
		.MuiDataGrid-row.folder:hover .MuiDataGrid-cell[data-field='name'] {
			border: 1px solid ${theme.palette.blue.main};
			border-radius: 8px;
			outline: none;
			cursor: pointer;
		}

		// Set blue background on row hover
		.MuiDataGrid-row:hover,
		.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft,
		.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedRight {
			background-color: ${theme.palette.blue.background} !important;
		}

		// Set pinned divider color
		.MuiDataGrid-columnHeader--withRightBorder,
		.MuiDataGrid-row .MuiDataGrid-cell--withRightBorder {
			border-right-color: ${theme.palette.action.divider};
		}

		// Hide focused cell styling
		.MuiDataGrid-columnHeader:focus,
		.MuiDataGrid-cell:focus {
			outline: none;
		}
	`,
);
