import { assertUnreachable } from '@warehouse/shared/util';
import { FilterOperators, FilterWithDate, InventoryFilter, PartialFilter } from './filters';

export function createFilter<Operator extends keyof FilterOperators = keyof FilterOperators>({
	field,
	operator,
	value,
	dateAuxiliary,
}: FilterWithDate<Operator>): FilterWithDate<Operator> {
	return {
		field,
		operator,
		value,
		dateAuxiliary,
	};
}

export function filterOutInvalidFilters(partialFilters: PartialFilter[]): InventoryFilter[] {
	return partialFilters.filter((partialFilter): partialFilter is InventoryFilter => {
		if (!partialFilter.field || !partialFilter.operator) return false;

		const { operator, value } = partialFilter;
		const normalizedValue = value === '' ? undefined : value;

		switch (operator) {
			case 'contains':
			case 'doNotContain':
			case 'startsWith':
			case 'endsWith':
				return typeof normalizedValue === 'string';

			case 'equals':
			case 'doesNotEqual':
				return ['string', 'boolean', 'number'].includes(typeof normalizedValue) || value instanceof Date;

			case 'isAnyOf':
			case 'isNoneOf':
				return (
					Array.isArray(normalizedValue) &&
					normalizedValue.every((item) => typeof item === 'string' || typeof item === 'number')
				);

			case 'isBefore':
			case 'isAfter':
			case 'isOnOrAfter':
			case 'isOnOrBefore':
				return normalizedValue instanceof Date;

			case 'isEmpty':
			case 'isDefined':
				return normalizedValue === undefined;

			case 'isLess':
			case 'isMore':
			case 'isEqualOrMore':
			case 'isEqualOrLess':
				return typeof normalizedValue === 'number';

			case 'includes':
			case 'includesOnly':
			case 'doesNotInclude':
				return Array.isArray(normalizedValue) && normalizedValue.every((item) => typeof item === 'string');

			default:
				assertUnreachable(operator);
				return false;
		}
	});
}
