import React, { useContext } from 'react';
import { Portal } from '@mui/material';
import { ChildrenList } from '@warehouse/title/feature-list-children';
import { TitleFull as Title } from '@warehouse/title/core';
import styled, { css } from 'styled-components';
import { RelationshipEditor, ErrorBoundary } from '@warehouse/title/feature-edit-relationship';
import { useSnackbar } from 'notistack';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { getWorkType, movieUuid } from '@warehouse/title/infra';
import { useStateQuery } from '../../../../utils/hooks/useStateQuery';
import { ChildrenTabNameOutput } from '../../hooks/useChildrenTabName';
import { CreateRelationshipsButton } from './CreateRelationshipsButton';
import { UnsavedChangesContext } from '../../contexts/UnsavedChangesContext';

interface ChildrenTabProps {
	title: Title;
	tabProperties: ChildrenTabNameOutput;
}

export function ChildrenTab({ title, tabProperties }: ChildrenTabProps) {
	const { promptUserIfUnsavedChanges } = useContext(UnsavedChangesContext);
	const [editChildrenUuid, setEditChildrenUuid] = useStateQuery<string>({ queryName: 'editChildren' });
	const compObjChoice =
		tabProperties.childWorkTypeUuid === movieUuid()
			? ({ isCompObj: true, relationshipType: undefined } as const)
			: ({ isCompObj: false, relationshipType: tabProperties.relationshipType! } as const);

	const { enqueueSnackbar } = useSnackbar();

	const isValidCompObjEntries = (child: IndexedTitleV2) => {
		const compObjEntries = title.metadata.coreMetadata.compilationObject?.compilationEntries || [];

		return !(compObjChoice.isCompObj && !compObjEntries.find((entry) => entry.contentId === child.uuid));
	};

	return (
		<Wrapper>
			<ChildrenList
				searchInputAction={<CreateRelationshipsButton title={title} tabProperties={tabProperties} />}
				title={title}
				label={tabProperties.pluralLabel}
				relationshipType={tabProperties.relationshipType}
				onChildrenClick={(child) => {
					if (!isValidCompObjEntries(child)) {
						enqueueSnackbar('Child not found', { variant: 'warning' });
						return;
					}
					setEditChildrenUuid(child.uuid);
				}}
				includedWorkTypes={[getWorkType(tabProperties.childWorkTypeUuid)]}
			/>
			<Portal container={document.body}>
				<ErrorBoundary
					onErrorRender={() => {
						enqueueSnackbar('Child not found', { variant: 'warning' });
						setEditChildrenUuid(null);
					}}
				>
					<RelationshipEditor
						parentUuid={title.uuid}
						childUuid={editChildrenUuid}
						{...compObjChoice}
						onClose={(force?: boolean) => {
							if (force) setEditChildrenUuid(null);
							else promptUserIfUnsavedChanges(() => setEditChildrenUuid(null));
						}}
					/>
				</ErrorBoundary>
			</Portal>
		</Wrapper>
	);
}

const Wrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		height: calc(100% - 2 * ${theme.spacing(2)});
		padding: ${theme.spacing(2)};
	`,
);
