import { definePersistentQueryParamStore } from '@warehouse/shared/util';
import type { StateCreator } from 'zustand/vanilla';
import { GridFilterModel, GridLogicOperator } from '../table/muix-filter';

export interface TableColumnSort {
	field: string;
	sort: 'asc' | 'desc' | null | undefined;
}

export type TableVisibility = { [key: string]: boolean };
export interface TablePinnedColumns {
	left: string[];
	right: string[];
}

export interface TableManagerStore {
	sort: TableColumnSort[];
	filter: GridFilterModel;
	visibility: TableVisibility;
	pinned: TablePinnedColumns;
	order: string[];
	actions: {
		setSort: (sortModel: TableColumnSort[]) => void;
		setVisibility: (visibilityModel: TableVisibility) => void;
		setPinned: (pinnedModel: TablePinnedColumns) => void;
		setOrder: (order: string[]) => void;
		setFilter: (filter: GridFilterModel) => void;
	};
}

export const tableManagerStoreSlice: StateCreator<TableManagerStore> = (set) => ({
	sort: [],
	filter: {
		items: [],
		logicOperator: GridLogicOperator.AND,
	},
	visibility: {},
	pinned: {
		left: [],
		right: [],
	},
	order: [],
	actions: {
		setSort: (value: TableColumnSort[]) => set({ sort: value }),
		setVisibility: (value: TableVisibility) => set({ visibility: value }),
		setPinned: (pinnedModel: TablePinnedColumns) => set({ pinned: pinnedModel }),
		setOrder: (order: string[]) => set({ order }),
		setFilter: (filter: GridFilterModel) => set({ filter }),
	},
});

export const [TableManagerProvider, useTableManager] =
	definePersistentQueryParamStore<TableManagerStore>(tableManagerStoreSlice);

export const tableManagerStoreSelector = {
	filter: (state: TableManagerStore) => state.filter,
	sort: (state: TableManagerStore) => state.sort,
	visibility: (state: TableManagerStore) => state.visibility,
	pinned: (state: TableManagerStore) => state.pinned,
	order: (state: TableManagerStore) => state.order,
};
