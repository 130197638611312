import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { TitleFull as Title } from '@warehouse/title/core';
import { DropdownV2 } from '@warehouse/shared/ui';
import useWorkTypes from '../../../utils/hooks/titles/useWorkTypes';
import { ChildrenTabNameOutput } from '../../title/hooks/useChildrenTabName';
import { TitleInfoWrapper } from './styles';

interface WorkTypeSelectorProps {
	selectedParent?: Title | undefined;
	loadingParent?: boolean;
	workType: string | undefined;
	setWorkType: (titleType: string) => void;
	setParentType: (parentType: string) => void;
	childrenProperties?: ChildrenTabNameOutput;
	forcedWorkTypeUuid?: string;
}

function WorkTypeSelector({
	selectedParent,
	loadingParent = false,
	workType,
	setWorkType,
	setParentType,
	childrenProperties,
	forcedWorkTypeUuid,
}: WorkTypeSelectorProps) {
	const { options, getWorkTypesByRelationshipSource } = useWorkTypes();

	const computedOptions = useMemo(() => {
		if (selectedParent) {
			if (!childrenProperties?.relationshipType) return [];
			return getWorkTypesByRelationshipSource(childrenProperties.relationshipType);
		}
		return options;
	}, [childrenProperties, options, getWorkTypesByRelationshipSource, selectedParent]);

	return (
		<TitleInfoWrapper>
			<Typography variant="s2Medium" color="text.primary">
				Title Type* :
			</Typography>
			<div style={{ width: '150px' }}>
				<DropdownV2
					ariaLabel="Select Work Type"
					withSearch
					loading={loadingParent}
					height={40}
					listItemHeight={40}
					readOnly={!!forcedWorkTypeUuid || (!loadingParent && computedOptions.length <= 1)}
					options={computedOptions}
					value={forcedWorkTypeUuid || workType || null}
					onChange={(_workType) => {
						setWorkType(_workType as string);
						setParentType('all');
					}}
				/>
			</div>
		</TitleInfoWrapper>
	);
}

export default WorkTypeSelector;
