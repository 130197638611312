import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { LicenseInfo } from '@mui/x-license';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import './index.css';
import { FeatureId, isFeatureEnabledForCurrentTenant } from '@warehouse/shared/config';
import { QueryParamsManagerImpl } from '@warehouse/shared/util';
import { BatchCreateStoreProvider } from '@warehouse/title/feature-create-titles';
import { TitleReportStoresProvider } from '@warehouse/title/feature-title-report';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Inventory } from './views/inventory';
import ProfilesList from './views/profiles/list';
import { AdminTitleView } from './views/title';
import AdminTitlesView from './views/titles';
import CreateMultipleTitlesView from './views/titles/createTitles';
import Editor from './views/profiles/editor';
import { initDatadogMonitoring } from './initDatadogMonitoring';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Redirect from './components/library/Redirect';
import PrivateRoute from './components/auth/PrivateRoute';
import Callback from './components/auth/Callback';

// Theme
import { ThemeProviders } from './theme';
import LicensorPicklistContextProvider from './views/titles/createTitles/LicensorPicklistContext';

if (process.env.REACT_APP_MUI_X_LICENSE_KEY) LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Router
const router = createBrowserRouter([
	{
		path: '/callback',
		element: <Callback />,
	},
	{
		path: '/',
		element: (
			<PrivateRoute>
				<App />
			</PrivateRoute>
		),
		children: [
			{
				path: '/',
				element: <Redirect to="/titles" />,
			},
			{
				path: '/titles',
				element: (
					<TitleReportStoresProvider>
						<AdminTitlesView />
					</TitleReportStoresProvider>
				),
			},
			{
				path: '/titles/create',
				element: (
					<BatchCreateStoreProvider>
						<LicensorPicklistContextProvider>
							<CreateMultipleTitlesView />
						</LicensorPicklistContextProvider>
					</BatchCreateStoreProvider>
				),
			},
			{
				path: '/title/:id',
				element: <AdminTitleView />,
			},
			...addRouteIfFeatureFlagEnabled('top-bar-inventory-button', {
				path: '/inventory',
				element: <Inventory />,
			}),
			...addRouteIfFeatureFlagEnabled('top-bar-profiles-button', {
				path: '/profiles',
				element: <ProfilesList />,
			}),
			...addRouteIfFeatureFlagEnabled('top-bar-editor-button', {
				path: '/editor',
				element: <Editor />,
			}),
		],
	},
]);

function addRouteIfFeatureFlagEnabled(feature: FeatureId, route: RouteObject): RouteObject[] {
	if (isFeatureEnabledForCurrentTenant(feature)) return [route];
	return [];
}

const onRedirectCallback = (appState: any) => {
	QueryParamsManagerImpl.getInstance().delete('state');
	QueryParamsManagerImpl.getInstance().delete('code');
	router.navigate(appState?.returnTo ? appState.returnTo : '/', {
		replace: true,
	});
};

root.render(
	<React.StrictMode>
		<ThemeProviders>
			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
				clientId={process.env.REACT_APP_AUTH0_CLIENTID || ''}
				authorizationParams={{
					redirect_uri: `${window.location.origin}/callback`,
					audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
					scope: 'openid profile email warehouse.read warehouse.write',
				}}
				onRedirectCallback={onRedirectCallback}
				cacheLocation="localstorage"
			>
				<RouterProvider router={router} />
			</Auth0Provider>
		</ThemeProviders>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initDatadogMonitoring();
