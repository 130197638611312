import { Path } from '../path-utils';
import { StorageTierKey, StorageTierLabels } from './storage-tier';

export enum InventoryItemType {
	Folder = 'folder',
	File = 'file',
}

export interface InventoryItemCommon {
	id: string;
	name: string;
	folder: Path;
	lastModifiedAt: Date;
}

export interface InventoryItemFolder extends InventoryItemCommon {
	itemType: InventoryItemType.Folder;
}

export interface InventoryItemFile extends InventoryItemCommon {
	itemType: InventoryItemType.File;
	fileSize: Byte;
	eTag: string;
	storageTier: StorageTier;
	online: boolean;
}

export type Byte = number & {
	__brand: 'Byte';
};

export type StorageTier<K extends StorageTierKey = StorageTierKey> = {
	value: K;
	label: (typeof StorageTierLabels)[K];
};

export type InventoryItem = InventoryItemFile | InventoryItemFolder;
export type InventoryItems = InventoryItem[];
