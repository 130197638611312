import React from 'react';
import { inventoryStoreSelector, useInventoryStore } from '@warehouse/inventory/domain';
import { PaginationNavigatorCore } from '@warehouse/shared/ui';

export function InventoryPaginationNavigator({ centered = false }: PaginationNavigatorProps) {
	const actions = useInventoryStore(inventoryStoreSelector.actions);
	const page = useInventoryStore(inventoryStoreSelector.page);
	const totalPages = useInventoryStore(inventoryStoreSelector.totalPages);
	const perPage = useInventoryStore(inventoryStoreSelector.perPage);

	return (
		<PaginationNavigatorCore
			centered={centered}
			actions={actions}
			page={page}
			totalPages={totalPages}
			perPage={perPage}
		/>
	);
}

interface PaginationNavigatorProps {
	centered?: boolean;
}
