import isEqual from 'lodash/isEqual';
import { PaginatorStore, TableManagerStore } from '@warehouse/shared/ui';
import { deepClone, PersistQueryParamsConfig } from '@warehouse/shared/util';
import { GridLogicOperator } from '../../shared/ui/table/muix-filter';

export const paginatorStoreConfig: PersistQueryParamsConfig<PaginatorStore> = {
	page: {
		type: 'number',
		defaultValue: 1,
	},
	perPage: {
		type: 'number',
		defaultValue: 50,
		sessionStorageKeyPrefix: 'inventory-list',
	},
};

export const defaultStorageTierFilterId = 1;
export const tableManagerStoreConfig: PersistQueryParamsConfig<TableManagerStore> = {
	sort: {
		type: 'array',
		defaultValue: [{ field: 'name', sort: 'asc' }],
		sessionStorageKeyPrefix: 'inventory-list',
	},
	visibility: {
		type: 'object',
		keyName: 'column-visibility',
		defaultValue: {},
		sessionStorageKeyPrefix: 'inventory-list',
	},
	pinned: {
		type: 'object',
		keyName: 'pinned-columns',
		defaultValue: {
			left: ['itemType', 'name'],
			right: [],
		},
		sessionStorageKeyPrefix: 'inventory-list',
	},
	order: {
		type: 'array',
		keyName: 'column-order',
		defaultValue: ['itemType', 'name', 'fileSize', 'lastModifiedAt', 'storageTier', 'eTag'],
		sessionStorageKeyPrefix: 'inventory-list',
	},
	filter: {
		type: 'object',
		keyName: 'filters',
		defaultValue: {
			items: [{ field: 'storageTier', operator: 'not', value: 'OFFLINE' }],
			logicOperator: GridLogicOperator.AND,
		},
		sessionStorageKeyPrefix: 'inventory-list',
		customSerializer: {
			isDefault(value, defaultValue) {
				const cleanedValue = deepClone(value);
				cleanedValue.items.forEach((_, idx) => {
					// this is a non-typed key from muix internal type
					delete (cleanedValue.items[idx] as any).fromInput;
					delete cleanedValue.items[idx].id;
				});
				return isEqual(cleanedValue, defaultValue);
			},
		},
	},
};
