import {
	InventoryItem,
	InventoryItemFile,
	InventoryItemFolder,
	InventoryItems,
	InventoryItemType,
	PathUtils,
} from '@warehouse/inventory/core';
import { assertUnreachable } from '@warehouse/shared/util';
import { components } from './inventory.openapi';
import { byte } from '../core/byte.service';
import { StorageTierAdapter } from './storage-tier.adapter';

export class InventoryAdapter {
	static adapt(
		items: (components['schemas']['InventoryFile'] | components['schemas']['InventoryFolder'])[],
	): InventoryItems {
		return items
			.map((item) => {
				if (item.itemType === 'file') return this.#adaptFile(item);
				if (item.itemType === 'folder') return this.#adaptFolder(item);

				assertUnreachable(item, `Invalid item type: ${(item as any).itemType}`);
				return null;
			})
			.filter((item): item is InventoryItem => !!item);
	}

	static #adaptFile(item: components['schemas']['InventoryFile']): InventoryItemFile {
		return {
			...item,
			fileSize: byte(item.fileSize),
			lastModifiedAt: new Date(item.lastModifiedAt),
			storageTier: StorageTierAdapter.adapt(item.storageTier),
			itemType: InventoryItemType.File,
			folder: PathUtils.parsePath(item.id)[0],
		} satisfies InventoryItemFile;
	}

	static #adaptFolder(item: components['schemas']['InventoryFolder']): InventoryItemFolder {
		return {
			...item,
			lastModifiedAt: new Date(item.lastModifiedAt),
			itemType: InventoryItemType.Folder,
			folder: PathUtils.parsePath(item.id)[0],
		} satisfies InventoryItemFolder;
	}
}
