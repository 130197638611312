import { singletonFactory } from '@warehouse/shared/util';
import { Filters, InventoryItem, InventoryRepository, WatchOptions } from '@warehouse/inventory/core';
import { Observable } from 'rxjs';
import { TableColumnSort } from '@warehouse/shared/ui';
import { PaginatedDocuments } from '@warehouse/shared/core';
import { InventoryFilter, InventoryFilterQuery } from '@warehouse/shared/filters';
import { inventoryFetchClientAdapter } from './inventory-fetch-client';
import { InventoryAdapter } from './inventory.adapter';
import { operations } from './inventory.openapi';

type DefinedTableColumnSort = TableColumnSort & { sort: NonNullable<TableColumnSort['sort']> };

type HttpFilterQuery = Exclude<
	Exclude<operations['post-search']['requestBody'], undefined>['content']['application/json']['filters'],
	undefined
>;

type OptionalHttpFilterQuery = HttpFilterQuery | undefined;

type HttpFilter = Exclude<HttpFilterQuery['filters'], undefined>[number];

export class InventoryRepositoryHttp implements InventoryRepository {
	#inventoryFetchClientAdapter = inventoryFetchClientAdapter;

	static #createFiltersHash(filters: Filters): string {
		const addToHashIfDefined = (hash: string, key: string, value: string | undefined): string => {
			if (value === undefined) return hash;
			if (hash === '') return `${key}=${value}`;

			return `${hash}&${key}=${value}`;
		};

		const { page, perPage } = filters.pagination;
		const pageHash = `${(page - 1) * perPage}-${page * perPage}`;
		const sortHash = filters.sort?.map((value) => `${value.field.toLowerCase()}-${value.sort}`).join(',') || undefined;
		const filtersHash = filters.filter
			? `${filters.filter.combinationOperator}${JSON.stringify(filters.filter.filters)}`
			: undefined;
		const wildCardSearchHash = undefined; // TODO: Implement in the `Inventory Wild-Card Search` cycle

		let hash = addToHashIfDefined('', 'page', pageHash);
		hash = addToHashIfDefined(hash, 'sort', sortHash);
		hash = addToHashIfDefined(hash, 'filters', filtersHash);
		hash = addToHashIfDefined(hash, 'wildCardSearch', wildCardSearchHash);

		return hash;
	}

	static #filterToHttpFilter(filter: InventoryFilter): HttpFilter {
		return {
			...filter,
			value: filter.value instanceof Date ? filter.value.toISOString() : filter.value,
		};
	}

	static #inventoryFilterQueryToHttpFilterQuery(
		filterQuery: InventoryFilterQuery | undefined,
	): OptionalHttpFilterQuery {
		if (!filterQuery) return undefined;

		return {
			...filterQuery,
			filters: filterQuery.filters.map(InventoryRepositoryHttp.#filterToHttpFilter),
		};
	}

	watchInventory(
		filters: Filters = { pagination: { page: 1, perPage: 50 } },
		options?: WatchOptions,
	): Observable<PaginatedDocuments<InventoryItem>> {
		return this.#inventoryFetchClientAdapter.watchObservableQuery({
			queryKey: ['inventory', InventoryRepositoryHttp.#createFiltersHash(filters)],
			queryFn: () => this.#getInventory(filters),
			refetchInterval: options?.pollInterval ?? 3000,
		});
	}

	async #getInventory(
		filters: Filters = { pagination: { page: 1, perPage: 50 } },
	): Promise<PaginatedDocuments<InventoryItem>> {
		const { error, data } = await this.#inventoryFetchClientAdapter.client.POST('/search', {
			body: {
				orderBy: filters.sort
					?.filter((value): value is DefinedTableColumnSort => !!value.sort)
					?.map((value) => ({
						field: value.field,
						direction: value.sort === 'asc' ? 'ASC' : ('DESC' as 'ASC' | 'DESC'),
					})),
				pagination: filters.pagination,
				filters: InventoryRepositoryHttp.#inventoryFilterQueryToHttpFilterQuery(filters.filter),
			},
		});

		if (error) throw new Error('Error fetching inventory');

		return {
			documents: InventoryAdapter.adapt(data.documents),
			pagination: data.page,
		};
	}
}

export const inventoryRepositorySingleton = singletonFactory<InventoryRepository>({
	factory: () => new InventoryRepositoryHttp(),
});
