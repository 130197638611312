import { StateCreator } from 'zustand';
import {
	TableColumnSort,
	TableManagerStore,
	tableManagerStoreSelector,
	tableManagerStoreSlice,
} from '@warehouse/shared/ui';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { InventoryItem } from '@warehouse/inventory/core';
import { deepClone } from '@warehouse/shared/util';
import { muixFilterModelToGridFilterModel } from '../../shared/ui/table/muix-filter';

export interface InventoryTableManagerStore extends TableManagerStore {
	actions: TableManagerStore['actions'] & InventoryTableManagerStoreActions;
}

export interface InventoryTableManagerStoreActions {
	onSortModelChange: (nextModel: TableColumnSort[]) => void;
	onColumnVisibilityModelChange: NonNullable<DataGridProProps<InventoryItem>['onColumnVisibilityModelChange']>;
	onPinnedColumnsChange: NonNullable<DataGridProProps<InventoryItem>['onPinnedColumnsChange']>;
	onColumnOrderChange: NonNullable<DataGridProProps<InventoryItem>['onColumnOrderChange']>;
	onFilterModelChange: NonNullable<DataGridProProps<InventoryItem>['onFilterModelChange']>;
}

export const inventoryTableManagerSlice: StateCreator<InventoryTableManagerStore> = (set, get, store) => {
	const tableManagerStore = tableManagerStoreSlice(set, get, store);

	return {
		...tableManagerStore,
		actions: {
			...tableManagerStore.actions,
			onSortModelChange: (nextValue) => {
				get().actions.setSort(nextValue);
			},
			onColumnVisibilityModelChange: (nextModel) => {
				const fixedVisibilityModel = deepClone(nextModel);
				Object.keys(fixedVisibilityModel).forEach((key) => {
					if (fixedVisibilityModel[key]) {
						delete fixedVisibilityModel[key];
					}
				});
				get().actions.setVisibility(fixedVisibilityModel);
			},
			onPinnedColumnsChange: (nextModel) => {
				get().actions.setPinned({
					left: nextModel.left ?? [],
					right: [],
				});
			},
			onColumnOrderChange: ({ column, oldIndex, targetIndex }) => {
				const { order } = get();
				const orderWithoutOld = [...order.slice(0, oldIndex), ...order.slice(oldIndex + 1)];
				get().actions.setOrder([
					...orderWithoutOld.slice(0, targetIndex),
					column.field,
					...orderWithoutOld.slice(targetIndex),
				]);
			},
			onFilterModelChange: (nextModel) => {
				get().actions.setFilter(muixFilterModelToGridFilterModel(nextModel)); // Safe conversion, our GridFilterModel is just more precise for the type of operator
			},
		},
	};
};

export const inventoryTableManagerSelector = {
	...tableManagerStoreSelector,
	actions: (state: InventoryTableManagerStore): InventoryTableManagerStoreActions => ({
		onFilterModelChange: state.actions.onFilterModelChange,
		onColumnVisibilityModelChange: state.actions.onColumnVisibilityModelChange,
		onPinnedColumnsChange: state.actions.onPinnedColumnsChange,
		onColumnOrderChange: state.actions.onColumnOrderChange,
		onSortModelChange: state.actions.onSortModelChange,
	}),
};
