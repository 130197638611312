import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { InventoryItem, InventoryItemType } from '@warehouse/inventory/core';

function getInventoryItemIconsByItemType(itemType?: InventoryItemType) {
	switch (itemType) {
		case InventoryItemType.File:
			return InsertDriveFileIcon;
		case InventoryItemType.Folder:
			return FolderIcon;
		default:
			return HelpCenterIcon;
	}
}

export function IconCell({ value }: GridRenderCellParams<InventoryItem, InventoryItemType>) {
	const Icon = getInventoryItemIconsByItemType(value);
	return <Icon style={{ verticalAlign: 'middle' }} />;
}
