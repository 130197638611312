import { assertUnreachable } from '@warehouse/shared/util';
import { FilterOperator, filterOutInvalidFilters, InventoryFilterQuery } from '@warehouse/shared/filters';
import {
	getGridSingleSelectOperators,
	GridFilterInputMultipleSingleSelect,
	GridFilterModel as MuixFilterModel,
	GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { CombinationOperator } from '../../../title/domain/SearchFilters';

/**
 * Filter item definition interface.
 * @demos
 *   - [Custom filter operator](/x/react-data-grid/filtering/customization/#create-a-custom-operator)
 */
export interface GridFilterItem {
	/**
	 * Must be unique.
	 * Only useful when the model contains several items.
	 */
	id?: number | string;
	/**
	 * The column from which we want to filter the rows.
	 */
	field: string;
	/**
	 * The filtering value.
	 * The operator filtering function will decide for each row if the row values is correct compared to this value.
	 */
	value?: any;
	/**
	 * The name of the operator we want to apply.
	 */
	operator: StringFilterOperator | NumericFilterOperator | DateFilterOperator;
}

export enum GridLogicOperator {
	AND = 'and',
	OR = 'or',
}

export interface GridFilterModel {
	/**
	 * @default []
	 */
	items: GridFilterItem[];
	/**
	 * - `GridLogicOperator.And`: the row must pass all the filter items.
	 * - `GridLogicOperator.Or`: the row must pass at least on filter item.
	 * @default GridLogicOperator.And
	 */
	logicOperator?: GridLogicOperator;
	/**
	 * - `GridLogicOperator.And`: the row must pass all the values.
	 * - `GridLogicOperator.Or`: the row must pass at least one value.
	 * @default GridLogicOperator.And
	 */
	quickFilterLogicOperator?: GridLogicOperator;
}

export type StringFilterOperator =
	| 'contains'
	| 'doesNotContain'
	| 'equals'
	| 'doesNotEqual'
	| 'startsWith'
	| 'endsWith'
	| 'isEmpty'
	| 'isNotEmpty'
	| 'isAnyOf'
	| 'isNoneOf';

export type NumericFilterOperator =
	| '='
	| '!='
	| '>'
	| '>='
	| '<'
	| '<='
	| 'isEmpty'
	| 'isNotEmpty'
	| 'isAnyOf'
	| 'isNoneOf';

export type DateFilterOperator =
	| 'is'
	| 'not'
	| 'after'
	| 'onOrAfter'
	| 'before'
	| 'onOrBefore'
	| 'isEmpty'
	| 'isNotEmpty';

export function gridFilterModelToFilterQuery(model: GridFilterModel): InventoryFilterQuery {
	return {
		combinationOperator: model.logicOperator ? (model.logicOperator.toUpperCase() as CombinationOperator) : 'AND',
		filters: filterOutInvalidFilters(
			model.items.map((item) => ({
				field: item.field,
				operator: supportedOperatorToFilterOperator(item.operator),
				value: item.value,
			})),
		),
	};
}

export function supportedOperatorToFilterOperator(operator: string): FilterOperator {
	if (isOperatorSupportedNumeric(operator)) return numericFilterOperatorToFilterOperator(operator);
	if (isOperatorSupportedString(operator)) return stringFilterOperatorToFilterOperator(operator);
	if (isOperatorSupportedDate(operator)) return dateFilterOperatorToFilterOperator(operator);
	console.error(`Unsupported operator: ${operator}`);
	return 'equals';
}

export function isOperatorSupportedNumeric(operator: string): operator is NumericFilterOperator {
	return (
		['=', '!=', '>', '>=', '<', '<=', 'isEmpty', 'isAnyOf', 'isNoneOf', 'isNotEmpty'] satisfies NumericFilterOperator[]
	).includes(operator as NumericFilterOperator);
}

export function isOperatorSupportedString(operator: string): operator is StringFilterOperator {
	return (
		[
			'contains',
			'doesNotContain',
			'equals',
			'doesNotEqual',
			'startsWith',
			'endsWith',
			'isEmpty',
			'isNotEmpty',
			'isAnyOf',
			'isNoneOf',
		] satisfies StringFilterOperator[]
	).includes(operator as StringFilterOperator);
}

export function isOperatorSupportedDate(operator: string): operator is DateFilterOperator {
	return (
		['is', 'not', 'after', 'onOrAfter', 'before', 'onOrBefore', 'isEmpty', 'isNotEmpty'] satisfies DateFilterOperator[]
	).includes(operator as DateFilterOperator);
}

export function numericFilterOperatorToFilterOperator(filterOperator: NumericFilterOperator): FilterOperator {
	switch (filterOperator) {
		case '=':
			return 'equals';
		case '!=':
			return 'doesNotEqual';
		case '>':
			return 'isMore';
		case '>=':
			return 'isEqualOrMore';
		case '<':
			return 'isLess';
		case '<=':
			return 'isEqualOrLess';
		case 'isEmpty':
			return 'isEmpty';
		case 'isAnyOf':
			return 'isAnyOf';
		case 'isNoneOf':
			return 'isNoneOf';
		case 'isNotEmpty':
			return 'isDefined';
		default:
			assertUnreachable(filterOperator);
			return 'equals';
	}
}

export function stringFilterOperatorToFilterOperator(filterOperator: StringFilterOperator): FilterOperator {
	switch (filterOperator) {
		case 'contains':
			return 'contains';
		case 'doesNotContain':
			return 'doNotContain';
		case 'equals':
			return 'equals';
		case 'doesNotEqual':
			return 'doesNotEqual';
		case 'startsWith':
			return 'startsWith';
		case 'endsWith':
			return 'endsWith';
		case 'isEmpty':
			return 'isEmpty';
		case 'isAnyOf':
			return 'isAnyOf';
		case 'isNoneOf':
			return 'isNoneOf';
		case 'isNotEmpty':
			return 'isDefined';
		default:
			assertUnreachable(filterOperator);
			return 'equals';
	}
}

export function dateFilterOperatorToFilterOperator(filterOperator: DateFilterOperator): FilterOperator {
	switch (filterOperator) {
		case 'is':
			return 'equals';
		case 'not':
			return 'doesNotEqual';
		case 'after':
			return 'isAfter';
		case 'onOrAfter':
			return 'isOnOrAfter';
		case 'before':
			return 'isBefore';
		case 'onOrBefore':
			return 'isOnOrBefore';
		case 'isEmpty':
			return 'isEmpty';
		case 'isNotEmpty':
			return 'isDefined';
		default:
			assertUnreachable(filterOperator);
			return 'equals';
	}
}

export function muixFilterModelToGridFilterModel(model: MuixFilterModel): GridFilterModel {
	return model as GridFilterModel; // Safe conversion, our GridFilterModel is simply more precise for the type of operator
}

export function gridFilterModelToMuixFilterModel(model: GridFilterModel): MuixFilterModel {
	return model as MuixFilterModel; // Safe conversion, our GridFilterModel is simply more precise for the type of operator
}

export function getCustomGridSingleSelectOperators(): GridFilterOperator[] {
	return [
		...getGridSingleSelectOperators(),
		{
			value: 'isNoneOf',
			label: 'is none of',
			getApplyFilterFn: (filterItem) => {
				if (filterItem.value == null || filterItem.value === '') {
					return null;
				}
				return ({ value }) =>
					// if none of the cell values corresponds to the filter item
					!value.some((cellValue: any) => cellValue === filterItem.value);
			},
			InputComponent: GridFilterInputMultipleSingleSelect,
		},
	];
}
