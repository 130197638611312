import { useMemo } from 'react';
import { TitleFull as Title, isEntryOf } from '@warehouse/title/core';
import { compilationUuid } from '@warehouse/title/infra';
import getDeepProperty from '../../../utils/getDeepProperty';
import { fromBasic } from '../../../utils/titleGetProperty';
import useWorkTypes from '../../../utils/hooks/titles/useWorkTypes';

export interface ChildrenTabNameOutput {
	childWorkTypeUuid: string;
	singularLabel: string;
	pluralLabel: string;
	relationshipType: string;
	addChildButtonOptions: string[];
}

export function useChildrenTabName(title: Title | undefined, excludedWorkTypes?: string[]): ChildrenTabNameOutput[] {
	const { getWorkType } = useWorkTypes();
	const currentWorkType = title ? getDeepProperty<string>(title, fromBasic(['workType'])) : undefined;
	return useMemo(() => {
		if (!currentWorkType) return [];
		const workType = getWorkType(currentWorkType);
		if (!workType) return [];
		const tabs = workType.tabsToDisplay.map((tab) => ({
			childWorkTypeUuid: tab.value,
			singularLabel: tab.labelSingular,
			pluralLabel: tab.labelPlural,
			relationshipType: workType.value === compilationUuid() ? isEntryOf() : tab.relationshipRequired,
			addChildButtonOptions: tab.addChildButtonOptions,
		}));
		if (excludedWorkTypes && excludedWorkTypes.length)
			return tabs.filter(({ childWorkTypeUuid }) => !excludedWorkTypes.includes(childWorkTypeUuid));
		return tabs;
	}, [getWorkType, currentWorkType, excludedWorkTypes]);
}
