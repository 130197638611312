import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Divider, Typography } from '@mui/material';

// LIBRARY
import { TextInput, Button } from '@warehouse/shared/legacy-ui';
import Icon from '@mdi/react';
import { mdiUpload } from '@mdi/js/commonjs/mdi';
import { mdiClose, mdiMagnify } from '@mdi/js';

export function PageHeader() {
	const [searchValue, setSearchValue] = useState('');
	return (
		<>
			<TopPageWrapper>
				<TopActionWrapper>
					<Typography variant="h4Bold">Inventory</Typography>
					<TopActionButtonWrapper>
						<Button nxstyle="secondary-blue" size="large" $fullHeight startIcon={<Icon path={mdiUpload} size="20px" />}>
							Upload
						</Button>
					</TopActionButtonWrapper>
				</TopActionWrapper>
				<Divider />
				<SearchBar
					placeholder="Search inventory"
					startIcon={<Icon path={mdiMagnify} size="24px" />}
					endIcon={
						<Button $disablePadding nxstyle="tertiary-light" onClick={() => setSearchValue('')}>
							<Icon path={mdiClose} size="16px" />
						</Button>
					}
					value={searchValue}
					onChange={(event) => {
						setSearchValue(event.target.value);
					}}
					height={32}
				/>
			</TopPageWrapper>
			{/* {error && <ErrorAlert style={{ margin: 16 }} errorMessage={error.message} />} */}
		</>
	);
}

const TopPageWrapper = styled.div``;

const TopActionWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		height: 26px;
		justify-content: space-between;
		padding: ${theme.spacing(2)};
	`,
);

const TopActionButtonWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		gap: ${theme.spacing(2)};
		height: 32px;
	`,
);

const SearchBar = styled(TextInput)(
	({ theme }) => css`
		margin: 0;
		padding: ${theme.spacing(2)};
	`,
);
