import { Byte } from './models/inventory-item';

export function isByte(value: number): value is Byte {
	return value >= 0;
}

export function byte(value: number): Byte {
	if (!isByte(value)) throw new Error('Byte value must be a positive number');
	return value as Byte;
}
