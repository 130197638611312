import { definePersistentQueryParamStore } from '@warehouse/shared/util';
import { StateCreator } from 'zustand';
import { inventoryRepositorySingleton } from '@warehouse/inventory/infra';
import { InventoryItems } from '@warehouse/inventory/core';
import React, { useEffect } from 'react';
import { Subscription } from 'rxjs';
import { createPaginatorSlice, PaginatorStore, paginatorStoreSelector, TableManagerStore } from '@warehouse/shared/ui';
import isEqual from 'lodash/isEqual';
import { paginatorStoreConfig, tableManagerStoreConfig } from './inventory.store-config';
import {
	inventoryTableManagerSelector,
	inventoryTableManagerSlice,
	InventoryTableManagerStore,
} from '../feature-list-inventory/inventory-table-manager.store';
import { gridFilterModelToFilterQuery } from '../../shared/ui/table/muix-filter';

export interface InventoryStore extends InventoryTableManagerStore, PaginatorStore {
	items: InventoryItems;
	isPending: boolean;
	internalActions: {
		destroy: () => void;
		initialize: () => void;
	};
	actions: InventoryTableManagerStore['actions'] & PaginatorStore['actions'] & {};
}

export const createInventoryStore: StateCreator<InventoryStore> = (set, get, store) => {
	const repo = inventoryRepositorySingleton.get();
	const isPending = true;
	let subscription: Subscription | undefined;

	const subscribeWithFilters = () => {
		if (subscription) subscription.unsubscribe();
		const filters = gridFilterModelToFilterQuery(get().filter);
		subscription = repo
			.watchInventory(
				{
					sort: [{ field: 'itemType', sort: 'desc' }, ...get().sort],
					pagination: {
						page: get().page,
						perPage: get().perPage,
					},
					filter: {
						...filters,
						filters: [...filters.filters, { field: 'folder', operator: 'equals', value: '/' }],
					},
				},
				{
					pollInterval: 3000,
				},
			)
			.subscribe((res) => {
				if (isPending) {
					set({ isPending: false });
				}
				set({
					items: res.documents,
					perPage: res.pagination.perPage,
					page: res.pagination.currentPage,
					totalPages: res.pagination.totalPage,
					totalCount: res.pagination.totalDocument,
				});
			});
	};

	store.subscribe((state, prevState) => {
		if (!isSortEqual(state, prevState) || !isPaginationEqual(state, prevState) || !isFilterEqual(state, prevState)) {
			subscribeWithFilters();
		}
	});

	const inventoryTableManagerStore = inventoryTableManagerSlice(set, get, store);
	const paginatorStore = createPaginatorSlice(set, get, store);

	return {
		...inventoryTableManagerStore,
		...paginatorStore,
		isPending,
		items: [],
		actions: {
			...inventoryTableManagerStore.actions,
			...paginatorStore.actions,
		},
		internalActions: {
			initialize() {
				subscribeWithFilters();
			},
			destroy() {
				if (subscription) subscription.unsubscribe();
			},
		},
	};
};

const [InventoryStoreProviderInternal, useInventoryStore] =
	definePersistentQueryParamStore<InventoryStore>(createInventoryStore);

export { useInventoryStore };

export const inventoryStoreSelector = {
	...inventoryTableManagerSelector,
	...paginatorStoreSelector,
	actions: (state: InventoryStore) => ({
		...inventoryTableManagerSelector.actions(state),
		...paginatorStoreSelector.actions(state),
	}),
	items: (state: InventoryStore) => state.items,
	isPending: (state: InventoryStore) => state.isPending,
};

function InventoryStoreEffects() {
	const { destroy, initialize } = useInventoryStore((state) => state.internalActions);

	useEffect(() => {
		initialize();
		return () => destroy();
	}, [initialize, destroy]);

	return null;
}

export function InventoryStoreProvider({ children }: { children: React.ReactNode }) {
	return (
		<InventoryStoreProviderInternal
			config={{
				...tableManagerStoreConfig,
				...paginatorStoreConfig,
			}}
		>
			<InventoryStoreEffects />
			{children}
		</InventoryStoreProviderInternal>
	);
}

function isSortEqual(state: TableManagerStore, prevState: TableManagerStore) {
	return isEqual(state.sort, prevState.sort);
}

function isPaginationEqual(state: PaginatorStore, prevState: PaginatorStore) {
	return state.page === prevState.page && state.perPage === prevState.perPage;
}

function isFilterEqual(state: TableManagerStore, prevState: TableManagerStore) {
	return isEqual(state.filter, prevState.filter);
}
