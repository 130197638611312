import { GridColDef } from '@mui/x-data-grid-pro';
import { filesize } from 'filesize';
import { InventoryItem, InventoryItemType, StorageTierService } from '@warehouse/inventory/core';
import { timeToString } from '../../../src/components/library/DatePicker/DatePicker';
import { IconCell, StorageTierCell } from './ui/fields';
import { getCustomGridSingleSelectOperators } from '../../shared/ui/table/muix-filter';

export const columns: GridColDef<InventoryItem>[] = [
	{
		field: 'itemType',
		headerName: 'Type',
		renderHeader: () => null,
		filterOperators: getCustomGridSingleSelectOperators(),
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		pinnable: true,
		hideable: false,
		filterable: false,
		sortable: false,
		renderCell: IconCell,
		resizable: false,
		width: 40,
	},
	{
		field: 'name',
		headerName: 'Name',
		flex: 1,
		hideable: false,
	},
	{
		field: 'fileSize',
		headerName: 'Size',
		type: 'number',
		valueFormatter: (_, row) => (row.itemType === InventoryItemType.File ? filesize(row.fileSize, { round: 1 }) : ''),
		width: 100,
	},
	{
		field: 'lastModifiedAt',
		headerName: 'Last Modified',
		type: 'date',
		valueFormatter: (_, row) => timeToString(row.lastModifiedAt),
		width: 160,
	},
	{
		field: 'storageTier',
		headerName: 'Storage Tier',
		renderCell: StorageTierCell,
		width: 170,
		type: 'singleSelect',
		valueOptions: StorageTierService.getStorageTierOptions(),
		filterOperators: getCustomGridSingleSelectOperators(),
	},
	{
		field: 'eTag',
		headerName: 'ETag',
		width: 300,
	},
];
